import React, { useState, useMemo, useCallback, useEffect } from 'react';
import Lottie from 'react-lottie';

export default function LottieComponent(props) {
  const { className = '', data, options = {} } = props;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: data,
    // rendererSettings: {
    //   preserveAspectRatio: 'xMidYMid slice',
    // },
    ...options,
  };

  const [isPaused, setIsPaused] = useState(false);
  const [isStopped, setIsStopped] = useState(props.isStopped || false);

  useEffect(() => {
    setIsPaused(props.isPaused);
  }, [props.isPaused]);
  useEffect(() => {
    // console.log(props.isStopped);
    setIsStopped(props.isStopped);
  }, [props.isStopped]);

  const handlePlay = useCallback(() => {
    setIsStopped(false);
    setIsPaused(false);
  }, []);

  const handleStop = useCallback(() => {
    setIsStopped(true);
  }, []);

  const handlePause = useCallback(() => {
    setIsPaused(!isPaused);
  }, [isPaused]);

  // console.log(props);

  return useMemo(() => {
    return (
      <div className={`pointer-events-none w-full h-full ${className}`}>
        <Lottie options={defaultOptions} height={'100%'} width={'100%'} isPaused={isPaused} isStopped={isStopped} />
      </div>
    );
  }, [isStopped, isPaused, handleStop, handlePlay, handlePause]);
}
